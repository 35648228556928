import { watchEffect } from "vue";
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

// States
import {
	cookies,
	authToken,
	saveToken,
	authStatus,
	isLoggedIn,
	updateAuthStatus,
} from "@/state/api/authState";
import { userInfos } from "@/state/api/userState";

// Composables
import useAccess from "@/composable/useAccess";

// Libraries
import { validToken, detectPlatform } from "@/utils";

// Third party libraries
import * as NProgress from "nprogress";

// APIs
import api from "@/api";
import { axios } from "@/services";

declare module "vue-router" {
	interface RouteMeta {
		requiresAuth: boolean;
		rule: string[];
	}
}

const platform = detectPlatform();

// Manual routes
const routes = [
	{
		path: "",
		redirect: { name: "auth-login" },
	},
	// Auth
	{
		component: () => import("/src/pages/auth.vue"),
		name: "auth",
		path: "/auth",
		props: true,
		children: [
			{
				path: "",
				redirect: { name: "auth-login" },
			},
			{
				component: () => import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/login.vue"),
				name: "auth-login",
				path: "login",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
			{
				component: () => import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/logout.vue"),
				name: "auth-logout",
				path: "logout/:action?",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
			{
				component: () =>
					import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/register.vue"),
				name: "auth-register",
				path: "register",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
			{
				component: () =>
					import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/forgot-password.vue"),
				name: "auth-forgot-password",
				path: "forgot-password",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
			{
				component: () =>
					import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/validate.vue"),
				name: "auth-validate",
				path: "validate/:id/:crsf",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
			// {
			// 	component: () =>
			// 		import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/condition-of-use.vue"),
			// 	name: "auth-condition-of-use",
			// 	path: "condition-of-use",
			// 	props: false,
			// 	meta: {
			// 		requiresAuth: false,
			// 		rule: [],
			// 		linkType: "normal",
			// 	},
			// },
			// {
			// 	component: () =>
			// 		import(/* webpackChunkName: "auth-group" */ "/src/pages/auth/privacy-policy.vue"),
			// 	name: "auth-privacyPolicy",
			// 	path: "privacy-policy",
			// 	props: false,
			// 	meta: {
			// 		requiresAuth: false,
			// 		rule: [],
			// 		linkType: "normal",
			// 	},
			// },
		],
	},
	// Workspace
	{
		component: () => import("/src/pages/workspace.vue"),
		name: "workspace",
		path: "/workspace",
		props: true,
		children: [
			{
				path: "",
				redirect: { name: "workspace-dashboard" },
			},
			// Dashboard
			{
				component: () => import("/src/pages/workspace/dashboards/Overview.vue"),
				name: "workspace-dashboard",
				path: "dashboard",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
			},
			// Agency
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-agency",
				path: "agency",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-agency-overview" },
					},
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/agencies/AgencyOverview.vue"
							),
						name: "workspace-agency-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: false,
							rule: [],
						},
					},
				],
			},
			// Operation
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-operations",
				path: "operations",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-operation-overview" },
					},
					// operations-overview
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/operations/OperationOverview.vue"
							),
						name: "workspace-operations-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// operations-new-passenger
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/operations/NewPassenger.vue"
							),
						name: "workspace-operations-new-passenger",
						path: "new-passenger",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// operations-passenger-baggage
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/operations/PassengerBaggages.vue"
							),
						name: "workspace-operations-passenger-baggage",
						path: "passenger-baggage",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/operations/PackagesRegistration.vue"
							),
						name: "workspace-operations-package-registration",
						path: "package-registration",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Auto Park
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-autopark",
				path: "autopark",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-autopark-overview" },
					},
					// autopark-overview
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/AutoparkOverview.vue"
							),
						name: "workspace-autopark-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// autopark-vehicle
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/VehicleAutopark.vue"
							),
						name: "workspace-autopark-vehicle",
						path: "vehicle",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// autopark-technical-visit
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/TechnicalVisitAutopark.vue"
							),
						name: "workspace-autopark-technical-visit",
						path: "technical-visit",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// Remove it
					// {
					// 	component: () => import(/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/ProvidersAutopark.vue"),
					// 	name: "workspace-autopark-providers",
					// 	path: "providers",
					// 	props: true,
					// 	meta: {
					// 		requiresAuth: true,
					// 		rule: [],
					// 	}
					// },
					// autopark-beverage - Consommation Carburant
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/BeverageAutopark.vue"
							),
						name: "workspace-autopark-beverage",
						path: "beverage", // Consommation Carburant
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// autopark-maintenance
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/MaintenanceAutopark.vue"
							),
						name: "workspace-autopark-maintenance",
						path: "maintenance", // Entretien
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// autopark-insurance
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/autopark/InsuranceAutopark.vue"
							),
						name: "workspace-autopark-insurance",
						path: "insurance",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Human Resources
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-human-resources",
				path: "human-resources",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-human-resources-overview" },
					},
					// human-resources-overview
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/human-resources/HumanResourcesOverview.vue"
							),
						name: "workspace-human-resources-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// human-resources-staff
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/human-resources/StaffHumanResources.vue"
							),
						name: "workspace-human-resources-staff",
						path: "staff",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// human-resources-salary
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/human-resources/SalaryHumanResources.vue"
							),
						name: "workspace-human-resources-salary",
						path: "salary",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Planning
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-planning",
				path: "planning",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-planning-overview" },
					},
					// planning-overview
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/planning/PlanningOverview.vue"
							),
						name: "workspace-planning-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// planning-embarkation
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/planning/EmbarkationPlanning.vue"
							),
						name: "workspace-planning-embarkation",
						path: "embarkation",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// planning-embarkation
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/planning/ExpeditionPlanning.vue"
							),
						name: "workspace-planning-expedition",
						path: "expedition",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// planning-others
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/planning/OthersPlanning.vue"
							),
						name: "workspace-planning-others",
						path: "others",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// planning-routes-and-rates
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/planning/RoutesAndRatesPlanning.vue"
							),
						name: "workspace-planning-routes-and-rates",
						path: "routes-and-rates",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Providers
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-providers",
				path: "providers",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-providers-overview" },
					},
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/providers/ProvidersOverview.vue"
							),
						name: "workspace-providers-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Report
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-report",
				path: "report",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-report-overview" },
					},
					// report-overview
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/reports/ReportsOverview.vue"
							),
						name: "workspace-report-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// report-travel-dockets
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/reports/TravelDocketsReport.vue"
							),
						name: "workspace-report-travel-dockets",
						path: "travel-dockets", // Bordereau de voyage
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// report-shipping-dockets
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/reports/ShippingDocketsReport.vue"
							),
						name: "workspace-report-shipping-dockets", // Bordereau d'expedition
						path: "shipping-dockets",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
					// report-route-dockets
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/reports/RouteDocketsReport.vue"
							),
						name: "workspace-report-route-dockets", // Bordereau d'expedition
						path: "route-dockets", // Bordereau de route
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
			// Settings
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-settings",
				path: "settings",
				props: true,
				meta: {
					requiresAuth: true,
					rule: [],
				},
				children: [
					{
						path: "",
						redirect: { name: "workspace-settings-overview" },
					},
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/settings/SettingsOverview.vue"
							),
						name: "workspace-settings-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: false,
							rule: [],
						},
					},
				],
			},
			// Profile
			{
				component: () => import("/src/layouts/SubLayout.vue"),
				name: "workspace-profile",
				path: "profile",
				props: true,
				children: [
					{
						path: "",
						redirect: { name: "workspace-profile-overview" },
					},
					{
						component: () =>
							import(
								/* webpackChunkName: "auth-group" */ "/src/pages/workspace/profile/ProfileOverview.vue"
							),
						name: "workspace-profile-overview",
						path: "overview",
						props: true,
						meta: {
							requiresAuth: true,
							rule: [],
						},
					},
				],
			},
		],
	},
	// Error
	{
		component: () => import("/src/pages/error.vue"),
		name: "error",
		path: "/error",
		props: true,
		meta: {
			requiresAuth: false,
			rule: [],
		},
		children: [
			{
				path: "",
				redirect: { name: "error-code" },
			},
			{
				component: () =>
					import(/* webpackChunkName: "workspace-errors" */ "/src/pages/error/error.vue"),
				name: "error-code",
				path: "code/:code",
				props: true,
				meta: {
					requiresAuth: false,
					rule: [],
					linkType: "normal",
				},
			},
		],
	},
	// Test
	{
		component: () => import("/src/pages/test.vue"),
		name: "test",
		path: "/test",
		props: true,
		meta: {
			requiresAuth: false,
			rule: [],
			linkType: "normal",
		},
	},
	// Any
	{
		path: "/:pathMatch(.*)",
		redirect: "/error/code/404",
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes, // manually set routes
});

router.beforeEach((routeTo, routeFrom, next) => {
	let stopWatch1: any = null;
	let stopWatch2: any = null;
	let stopWatch3: any = null;

	// Handle NProgress display on page changes
	NProgress.start();

	// Global function
	function redirectToLogin() {
		// Pass the original route to the login component
		next({ name: "auth-login", query: { redirect: routeTo.fullPath } });
	}

	function navigation() {
		// Verify if user logged in
		if (isLoggedIn.value == true) {
			if (routeTo.name === "auth-login" || routeTo.name === "auth-register") {
				next("/workspace/dashboard");
			} else {
				next();
			}
		} else {
			next();
		}
	}

	function nextAccess() {
		if (routeTo.meta.rule.length > 0) {
			if (useAccess("visibility", routeTo.meta.rule) == true) {
				if (
					useAccess("subscription", routeTo.meta.rule) == true &&
					useAccess("payment", []) == true
				) {
					next();
				} else {
					if (
						routeTo.matched[1].name == "workspace-overview" ||
						routeTo.matched[1].name == "workspace-profile"
					) {
						next();
					} else {
						next("/workspace/dashboard");
					}
				}
			} else {
				next("/error/code/403.2");
			}
		} else {
			next();
		}
	}

	function authenticatedNavigation() {
		// If auth is required and the user is logged in...
		if (isLoggedIn.value == true) {
			const token = validToken();
			if (token.status === "valid") {
				stopWatch1 = watchEffect(() => {
					if (
						authStatus.value != "initial" &&
						authStatus.value != "disconnected" &&
						authStatus.value != "refreshing" &&
						authStatus.value != "refreshed"
					) {
						// Go to route
						nextAccess();
						// Stop watch effect
						if (stopWatch1 != null) stopWatch1();
					}
				});
			} else if (token.status === "invalid") {
				// Force user disconection
				next("/auth/logout");
			} else {
				updateAuthStatus("initial");
				redirectToLogin();
			}
		} else {
			// If auth is required and the user is NOT currently logged in,
			// redirect to login.
			redirectToLogin();
		}
	}

	// Check if auth is required on this route
	// (including nested routes).
	const authRequired = routeTo.matched.some((route) => route.meta.requiresAuth);

	// If auth isn't required for the route, just continue.
	if (!authRequired) {
		if (authStatus.value == "loading") {
			stopWatch2 = watchEffect(() => {
				if (authStatus.value != "loading") {
					// Navigate to next page
					navigation();
					// Stop watch effect
					if (stopWatch2 != null) stopWatch2();
				}
			});
		} else {
			navigation();
		}
	} else {
		// If auth loading, wait to change
		if (authStatus.value == "loading") {
			stopWatch3 = watchEffect(() => {
				if (authStatus.value != "loading") {
					// Navigate to next authenticated page
					authenticatedNavigation();
					// Stop watch effect
					if (stopWatch3 != null) stopWatch3();
				}
			});
		} else {
			authenticatedNavigation();
		}
	}
});

router.afterEach(() => {
	NProgress.done();

	// Remove splashcreen
	const appLoading = document.getElementById("splashcreen");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
