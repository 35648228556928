// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	create: (data: any) => callAPI("POST", `${path.base}/agencies`, data, "creating a new station"),
	update: (data: any, id: any) =>
		callAPI("PUT", `${path.base}/agencies/${id}`, data, "updating a new station"),
	getAll: () => callAPI("GET", `${path.base}/agencies`, "", "getting a all stations"),
	get: (id: number) => callAPI("GET", `${path.base}/agencies/${id}`, "", "getting station infos"),

	users: {
		manager: {
			getAll: (stationID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/manager/all/${stationID}`,
					"",
					"getting All User Of Current station"
				),
			get: (stationID: number, userID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/manager/detail/1/1`,
					"",
					"getting User Detail of Current Station"
				),
			create: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/manager`,
					data,
					"Creating New User of Current Station"
				),
		},
		personal: {
			getAll: (stationID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/personal/all/${stationID}`,
					"",
					"getting All User Of Current station"
				),
			get: (stationID: number, userID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/personal/detail/1/1`,
					"",
					"getting User Detail of Current Station"
				),
			create: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/personal`,
					data,
					"Creating New User of Current Station"
				),
		},
	},
};

export default Routes;
