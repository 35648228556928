import { ref } from "vue";
import TravelRoutesService from "@/services/pages/TravelRoutesService";
import VehicleService from "@/services/pages/VehicleService";
import DriverService from "@/services/pages/DriverService";
import TicketTypeService from "@/services/pages/TicketTypeService";
import StationService from "@/services/pages/StationService";
import { currentStation, userInfos, getAllStationsUser } from "@/state/api/userState";

export const statusOK = ref(true);

export const enableNextStepButton = ref(false); // active le bouton Next dans l'assistance de configuration popup

export const wizardInit = ref(false); // prepare a modal page to be open
export const wizardComplete = ref(false); // use to open a success modal page
export const wizardClose = ref(false); // use to close a success modal page

export const recapitulationInfo: any = ref({
	vehicle: { label: "véhicule", status: 1, step: 1, routeName: "workspace-autopark-vehicle" },
	driver: { label: "chauffeur", status: 1, step: 2, routeName: "workspace-human-resources-staff" },
	travelRoute: {
		label: "itinéraire",
		status: 1,
		step: 3,
		routeName: "workspace-planning-routes-and-rates",
	},
	ticketType: {
		label: "type de ticket",
		status: 1,
		step: 4,
		routeName: "workspace-planning-routes-and-rates",
	},
	// stationService: { label: "véhicule", status: 1},
});

export const updateRecapilationInfoIfNecessary = async (param: string) => {
	if (!statusOK.value) {
		recapitulationInfo.value[param].status = 1;
		enableNextStepButton.value = true;
		await launchPrerequisitesService();
	}
};

export const resetDataPrerequisitesService = () => {
	wizardInit.value = false;
	wizardComplete.value = false;
	wizardClose.value = false;
	enableNextStepButton.value = false;
	statusOK.value = false;
};

export const launchPrerequisitesService = async () => {
	const travelRouteResp: any = await TravelRoutesService.getAll(
		"?station=" + currentStation.value.key
	);
	const vehicleResp: any = await VehicleService.getAll("?station=" + currentStation.value.key);
	const dvehicleResp: any = await DriverService.getAll("?station=" + currentStation.value.key);
	const tTypeResp: any = await TicketTypeService.getAll("?station=" + currentStation.value.key);
	// const eStationResp:any = await StationService.getAll("?station=" + currentStation.value.key)

	if (
		travelRouteResp.length == 0 ||
		vehicleResp.length == 0 ||
		dvehicleResp.length == 0 ||
		tTypeResp.length == 0 /*|| eStationResp.length == 0*/
	) {
		statusOK.value = false;

		if (travelRouteResp.length == 0) recapitulationInfo.value.travelRoute.status = 0;
		if (vehicleResp.length == 0) recapitulationInfo.value.vehicle.status = 0;
		if (dvehicleResp.length == 0) recapitulationInfo.value.driver.status = 0;
		if (tTypeResp.length == 0) recapitulationInfo.value.ticketType.status = 0;
	} else {
		statusOK.value = true;
		// fin de l'assistant de configuration
		wizardComplete.value = true;
	}
};

export default {
	run: async () => launchPrerequisitesService(),
};
