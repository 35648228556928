// Services
import callAPI from "../callApi";
import path from "../variables";

const personal = {
	getAll: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/automobile/drivers${filter ? filter : ""}`,
			"",
			"getting all Staff"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/automobile/drivers`, data, "creating a Staff"),
};

export default { personal };
