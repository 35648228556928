// Services
import callAPI from "../callApi";
import path from "../variables";

const Passengers = {
	getAll: (param?: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/passengers${param ? param : ""}`,
			"",
			"getting all passengers"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/tickets/passengers`, data, "register new passenger"),
};

export default Passengers;
