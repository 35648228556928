// Services
import callAPI from "../callApi";
import path from "../variables";

const route = {
	getAll: () => callAPI("GET", `${path.base}/planning/aa`, "", "getting all route dockets"),
};

const travel = {
	getAll: () =>
		callAPI("GET", `${path.base}/planning/traveldockets`, "", "getting all travel dockets"),
};

export default { travel, route };
