export default [
	// Dashboard
	{
		name: "dashboard",
		route: "workspace-dashboard",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_DASHBOARD),
		title: {
			en: "Dashboard",
			fr: "Tableau de bord",
		},
		elements: [],
		icon: {
			active: "/media/icons/dashboard-icon.svg",
		},
	},
	// Agency
	{
		name: "agency",
		route: "workspace-agency-overview",
		title: {
			en: "Agency",
			fr: "Agences",
		},
		elements: [],
		enable: JSON.parse(process.env.VUE_APP_ENABLE_AGENCY_OVERVIEW),
		icon: {
			active: "/media/icons/agency-icon.svg",
		},
	},
	// Operations
	{
		name: "operations",
		route: "workspace-operations-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_OPERATION_OVERVIEW),
		title: {
			en: "Operations",
			fr: "Opérations",
		},
		elements: [
			{
				route: "workspace-operations-new-passenger",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_OPERATION_PASSENGERS),
				title: {
					en: "New Passengers",
					fr: "Enreg. Passagers",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-passenger-baggage",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_OPERATION_BAGGAGES),
				title: {
					en: "Baggage Tickets",
					fr: "Etiquettes Bagages",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-package-registration",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_OPERATION_PACKAGE),
				title: {
					en: "Package Registration",
					fr: "Enregistrement Colis",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/operation-icon.svg",
		},
	},
	// autopark
	{
		name: "autopark",
		route: "workspace-autopark-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_OVERVIEW),
		title: {
			en: "Auto Park",
			fr: "Parc Automobile",
		},
		elements: [
			{
				route: "workspace-autopark-vehicle",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_VEHICLE),
				title: {
					en: "Vehicles",
					fr: "Véhicules",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-technical-visit",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_TECHNICAL_VISIT),
				title: {
					en: "Technical Visit",
					fr: "Visites Techniques",
				},
				second_title: {
					en: "Technical Visit",
					fr: "Visites Technique",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			// {
			// 	route: "workspace-autopark-providers",
			// 	user_role: ["root", "admin"],
			// 	title: {
			// 		en: "Providers",
			// 		fr: "Fournisseurs",
			// 	},
			// 	second_title: {
			// 		en: "Providers",
			// 		fr: "Fournisseurs",
			// 	},
			// 	icon: {
			// 		active: "/media/icons/....svg",
			// 		inactive: "/media/icons/....svg",
			// 	},
			// },
			{
				route: "workspace-autopark-beverage",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_BEVERAGE),
				title: {
					en: "Beverage",
					fr: "Consommations",
				},
				second_title: {
					en: "Beverage",
					fr: "Consommations",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-maintenance",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_MAINTENANCE),
				title: {
					en: "Maintenances",
					fr: "Entretiens",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-insurance",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_AUTOPACK_INSURANCE),
				title: {
					en: "Insurance",
					fr: "Assurances",
				},
				second_title: {
					en: "Insurance",
					fr: "Assurances",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/autopark-icon.svg",
		},
	},
	// human-resources
	{
		name: "human-resources",
		route: "workspace-human-resources-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_OVERVIEW),
		title: {
			en: "Human Resources",
			fr: "Ressources Humaines",
		},
		elements: [
			{
				route: "workspace-human-resources-staff",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_STAFF),
				title: {
					en: "Staff",
					fr: "Personnel",
				},
				second_title: {
					en: "Staff",
					fr: "Personnel",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-human-resources-salary",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_SALARY),
				title: {
					en: "Salary",
					fr: "Paiement Salaires",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/human-resources-icon.svg",
		},
	},
	// planning
	{
		name: "planning",
		route: "workspace-planning-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_PLANNING_OVERVIEW),
		title: {
			en: "Planning",
			fr: "Planifications",
		},
		elements: [
			{
				route: "workspace-planning-embarkation",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_PLANNING_EMBARKATION),
				title: {
					en: "Embarkations",
					fr: "Embarquements",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-expedition",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_PLANNING_EXPEDITION),
				title: {
					en: "Expeditions",
					fr: "Expéditions",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-routes-and-rates",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_PLANNING_ROUTE_RATES),
				title: {
					en: "Routes and Rates",
					fr: "Trajets et Tarifs",
				},
				second_title: {
					en: "routes and rates",
					fr: "Trajets et tarifs",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-others",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_PLANNING_OTHERS),
				title: {
					en: "Others",
					fr: "Autres",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/planification-icon.svg",
		},
	},
	// Provider
	{
		name: "providers",
		route: "workspace-providers-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_PROVIDER_OVERVIEW),
		title: {
			en: "Providers",
			fr: "Fournisseurs",
		},
		elements: [
			// {
			// 	route: "workspace-planning-travel-tickets",
			// 	user_role: ["root", "admin"],
			// 	title: {
			// 		en: "travel tickets",
			// 		fr: "Tickets de voyage",
			// 	},
			// 	second_title: {
			// 		en: "travel tickets",
			// 		fr: "Tickets de voyage",
			// 	},
			// 	icon: {
			// 		active: "/media/icons/....svg",
			// 		inactive: "/media/icons/....svg",
			// 	},
			// },
		],
		icon: {
			active: "/media/icons/provider-icon.svg",
		},
	},
	// Report
	{
		name: "reports",
		route: "workspace-report-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_REPORT_OVERVIEW),
		title: {
			en: "Reports",
			fr: "Rapports",
		},
		elements: [
			{
				route: "workspace-report-travel-dockets",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_REPORT_TRAVEL_DOCKET),
				title: {
					en: "Travel Dockets",
					fr: "Bordereaux de voyage",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-report-shipping-dockets",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_REPORT_SHIPPING_DOCKET),
				title: {
					en: "Shipping Dockets",
					fr: "Bordereaux d'expédition",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-report-route-dockets",
				user_role: ["root", "admin"],
				enable: JSON.parse(process.env.VUE_APP_ENABLE_REPORT_ROUTE_DOCKET),
				title: {
					en: "Route Dockets",
					fr: "Bordereaux de route",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/report-icon.svg",
		},
	},
	// logout
	{
		name: "logout",
		route: "auth-logout",
		title: {
			en: "Log Out",
			fr: "Déconnexion",
		},
		elements: [],
		icon: {
			active: "/media/icons/logout-icon.svg",
		},
	},
	// settings
	{
		name: "settings",
		route: "workspace-settings-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_SETTINGS),
		title: {
			en: "Settings",
			fr: "Paramètres",
		},
		elements: [],
		icon: {
			active: "/media/icons/settings-icon.svg",
		},
	},
	// profile
	{
		name: "profile",
		route: "workspace-profile-overview",
		enable: JSON.parse(process.env.VUE_APP_ENABLE_PROFILE),
		title: {
			en: "Profile",
			fr: "Profil",
		},
		elements: [],
		icon: {
			active: "/media/icons/settings-icon.svg",
		},
	},
];
