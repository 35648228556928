import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { cookies, authToken } from "@/state/api/authState";
import platform from "platform-detect";
import { userInfos } from "@/state/api/userState";

dayjs.extend(advancedFormat);

const getExpDiff = (exp: any) => {
	const today = dayjs();
	// We recover expiration date
	const expDate = dayjs.unix(exp);
	// We calculate difference between expDate & today
	const diff = expDate.diff(today, "s");
	return diff;
};

const validToken = () => {
	try {
		let token: any = null;
		if (
			detectPlatform().runtime != "cordova" &&
			detectPlatform().runtime != "electron" &&
			detectPlatform().browser != "safari"
		) {
			token =
				cookies.get("session") == undefined
					? { key: "", key_res: "", exp: 0, exp_in: 0 }
					: cookies.get("session");
		} else {
			token = authToken.value;
		}
		// We check if the token exists
		if (token != undefined && token != null && token != "" && token.key != "") {
			// We calculate difference between expDate & today
			const expDiff = getExpDiff(token.exp);

			// We check the validity of the token according to the difference between 2 dates
			if (expDiff <= 0) {
				return {
					status: "invalid",
					key: token.key,
					key_res: token.key_res,
					exp: token.exp,
					exp_in: token.exp_in,
				};
			} else {
				return {
					status: "valid",
					key: token.key,
					key_res: token.key_res,
					exp: token.exp,
					exp_in: token.exp_in,
				};
			}
		} else {
			return {
				status: "empty",
				key: "",
				key_res: "",
			};
		}
	} catch (error) {
		console.error("Error when retrieving token", error);
		return {
			status: "empty",
			key: "",
		};
	}
};

function setImage(image: any) {
	const api_url = process.env.VUE_APP_API;
	if (image) {
		if (image.startsWith("files/") || image.startsWith("images/") || image.startsWith("uploads/")) {
			return `${api_url}/${image}`;
		} else {
			return image;
		}
	} else {
		return "";
	}
}

function reduceText(text: string, length: number, ending?: string) {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = "...";
	}

	if (text != null) {
		if (text.length > length) {
			return text.substring(0, length - ending.length) + ending;
		} else {
			return text;
		}
	} else {
		return text;
	}
}

function detectPlatform() {
	const info = {
		os: "",
		browser: "",
		runtime: "",
	};

	// Detect OS
	if (platform.windows) {
		info.os = "windows";
	} else if (platform.macos) {
		info.os = "macos";
	} else if (platform.linux) {
		info.os = "linux";
	} else if (platform.linuxBased) {
		info.os = "linuxBased";
	} else if (platform.android) {
		info.os = "android";
	} else if (platform.ios) {
		info.os = "ios";
	} else if (platform.tizen) {
		info.os = "tizen";
	}

	// Detect browser
	if (platform.chrome) {
		info.browser = "chrome";
	} else if (platform.safari) {
		info.browser = "safari";
	} else if (platform.firefox) {
		info.browser = "firefox";
	} else if (platform.edge) {
		info.browser = "edge";
	} else if (platform.opera) {
		info.browser = "opera";
	} else if (platform.ie) {
		info.browser = "ie";
	}

	// Detect runtime
	if (platform.nwjs) {
		info.runtime = "nwjs";
	} else if (platform.electron) {
		info.runtime = "electron";
	} else if (platform.pwa) {
		info.runtime = "pwa";
	} else if (platform.cordova) {
		info.runtime = "cordova";
	} else if (platform.web) {
		info.runtime = "web";
	}

	return info;
}

const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return "tablet";
	}
	if (
		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		return "mobile";
	}
	return "desktop";
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
function formatDate(date: any, format = 0) {
	switch (format) {
		case 1:
			return dayjs(date).format("YYYY-MM-DD");
			break;
		case 2:
			return dayjs(date).format("DD MMM YYYY");
			break;
		default:
			return dayjs(date).format("DD MMMM YYYY").toString();
	}
}

const expireDateRemaining = (date: string) => {
	const expireDate = dayjs(date);
	if (expireDate.isAfter(dayjs())) {
		return Math.round(expireDate.diff(dayjs(), "day", true));
	} else {
		return 0;
	}
};

const getTimeWaiting = () => {
	// Variables
	const max_time = 3; // in minutes
	const today = dayjs();
	const create_date: any = userInfos.value?.paymentOperations[0]?.created_at;
	const create_date_cleaned = dayjs(create_date, "YYYY-MM-DD hh:mm:ss");
	const remaining_time = today.diff(create_date_cleaned, "minute");
	// return remaining_time;
	if (remaining_time >= max_time) {
		return { time: -1, max: max_time };
	} else {
		return { time: remaining_time, max: max_time };
	}
};

const makeFakeID = (length: number) => {
	let result = "bzile-";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength: number = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export {
	getExpDiff,
	validToken,
	setImage,
	reduceText,
	detectPlatform,
	getDeviceType,
	formatDate,
	expireDateRemaining,
	getTimeWaiting,
	makeFakeID,
};
