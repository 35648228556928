// Services
import callAPI from "../callApi";
import path from "../variables";

const Auth = {
	login: (data: any) => callAPI("POST", `${path.base}/auth/login`, data, "login user"),
	logout: () => callAPI("GET", `${path.base}/auth/logout`, "", "logout user"),
	register: (data: any) => callAPI("POST", `${path.base}/auth/register`, data, "registering user"),
	newUser: (data: any) => callAPI("POST", `${path.base}/user/new`, data, "create a new user"),
	validateAccount: (data: any) =>
		callAPI("POST", `${path.base}/auth/activate`, data, "validating account"),

	initForgotPassword: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/auth/init-reset-password`,
			data,
			"init requesting to reset password"
		),
	forgotPassword: (data: any) =>
		callAPI("POST", `${path.base}/auth/reset-password`, data, "requesting reset password"),

	verifyCode: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/auth/verify_code`,
			data,
			"requesting verify code for new password"
		),
	resetPassword: (data: any) =>
		callAPI("POST", `${path.base}/auth/reset-password`, data, "reseting password"),
	refreshToken: (data: any) =>
		callAPI("POST", `${path.base}/auth/refresh`, data, "refreshing token"),
	changePwd: (data: { old_password: string; password: string; passwordConfirm: string }) =>
		callAPI("POST", `${path.base}/new_password`, data, "Changing Password"),
};

export default Auth;
