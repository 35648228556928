export default {
	fr: {
		app: {
			appName: "Jahagal Software",
			author: "Mfoum Group SARL",
			urlAuthor: "https://www.mfoumgroup.com",
		},
		login: {
			actionPasswordForget: "Réinitialiser",
			passwordForget: "Mot de passe oublié ?",
			subTitlePage: "Veuiller saisir vos informations de connexion.",
			welcomeRegisterMsg: "Vous souhaitez bénéficier de cette plateforme ?",
			titlePage: "Authentification",
			textLoading: "Patienter SVP...",
			textButtonLogin: "Connexion",
			registerMsg: "Créer votre compte entreprise.",
			errorEmail: "Adresse E-mail invalide.",
			errorPassword: "Mot de passe invalide.",
		},
		forgotPassword: {
			actionLoginMsg: "connecter ?",
			msgLogin: "Voulez-vous vous ",
			subTitlePage: "Veuiller saisir votre adresse E-mail d'inscription.",
			titlePage: "Ré-initialisation de mot de passe",
			textLoading: "Patienter SVP...",
			textButtonForgotPassword: "Valider",
			registerMsg: "Créer votre compte entreprise.",
			errorEmail: "Adresse E-mail invalide.",
		},
		register: {
			adminInfo: "Compte administrateur",
			alreadyHaveAccount: "Je possède déjà un compte.",
			actionLogin: "Me connecter",
			companyInfo: "Informations de l'entreprise",
			errorCompanyName: "Le nom de la société doit comporter au moins 3 caractères",
			errorCompanyCountry: "Pays non choisi",
			errorCompanyCity: "Ville non choisi",
			errorPassword:
				"Le mot de passe doit comporter au moins 8 caractères et doit contenir au moins un des symboles suivant : @, #,$, %, ^, &, +, =",
			errorPasswordRepeat: "Le mot de passe ne correspond pas.",
			errorUserName: "Le nom d'utilisateur doit comporter au moins 3 caractères",
			errorEmail: "L'adresse email n'est pas valide.",
			inputLocation: "Localisation (Pays et Ville)",
			errorCompanyPhone: "Numéro de téléphone non valide",
			inputPhone: "Contact de l'entrprise",
			titlePage: "Création de compte",
			textLoading: "Création...",
			textButtonLogin: "Créer le compte",
			subTitlePage: "Veuiller saisir vos informations de connexion.",
			welcomeRegisterMsg: "Vous souhaitez bénéficier de cette plateforme ?",
		},
		validation: {
			errorVerificationCode: "Code de vérification invalide.",
			errorFillField: "Veuillez remplir tous les champs correctement.",
			inputCode: "Entrer le code de vérification",
			subTitlePage:
				"Nous vous avons envoyé un code de vérification à votre adresse d'enregistrement. Veuillez consulter votre boîte de messagerie électronique.",
			subTitlePageSuccessValidated: "Votre compte a été validé avec succès.",
			subTitlePageAlreadyValidated:
				"Utiliser votre adresse email et le mot de passe que vous avez utilisés pour vous connecter.",
			subTitlePageUnknowError:
				"Nous n'avons pas pu valider votre compte. Veuillez contacter votre votre responsable technnique",
			textButtonvalidateCode: "Valider le compte",
			textLoading: "Patienter SVP...",
			textButtonErrorValidateCode: "Retour à la page d'authentification",
			textButtonSuccessValidateCode: "Retour à la page d'authentification",
			titlePage: "Validation du compte",
			titlePageSuccess: "Félicitations",
			titlePageAlreadyValidated: "Compte déjà validé",
			unableValidateAccount: "Impossible de valider votre compte. Veuillez réessayer plus tard.",
		},
		notification: {
			msgAuthenticationError: "Erreur d'authentification",
			msgRegistrationErrorWithStatusSuccess: "Erreur de création de compte.",
		},
	},
	en: {
		app: {
			appName: "Jahagal Software",
			author: "Mfoum Group SARL",
			urlAuthor: "https://www.mfoumgroup.com",
		},
		login: {
			actionPasswordForget: "Reset",
			passwordForget: "Forgot your password?",
			welcomeRegisterMsg: "You wish to benefit from this platform?",
			subTitlePage: "Please enter your login information",
			textButtonLogin: "Login",
			titlePage: "Authentication",
			textLoading: "Please wait...",
			registerMsg: "Create your company account.",
			errorEmail: "Invalid email address.",
			errorPassword: "Invalid password.",
		},
		register: {
			actionLogin: "Log me in",
			adminInfo: "Administrator account",
			alreadyHaveAccount: "I already have an account.",
			companyInfo: "Company information",
			companyCountry: "Phone number is invalid",
			errorPasswordRepeat: "The password does not match.",
			errorCompanyName: "Company name must be at least 3 characters long",
			errorUserName: "User name must be at least 3 characters long",
			errorCompanyCountry: "Country is required.",
			errorCompanyCity: "City is required.",
			errorPassword:
				"The password must be at least 8 characters long and must contain @, #,$, %, ^, &, +, =",
			errorEmail: "Email must be valid",
			errorCompanyPhone: "Phone number is invalid",
			inputCompany: "Company name",
			inputLocation: "Location (Country and City)",
			inputPhone: "Company contact",
			subTitlePage: "Please enter your login information.",
			titlePage: "Create an account",
			textLoading: "Creation...",
			textButtonLogin: "Create account",
			welcomeRegisterMsg: "You wish to benefit from this platform?",
		},
		forgotPassword: {
			actionLoginMsg: "se connecter ?",
			msgLogin: "Je possède un mot de passe; me ",
			subTitlePage: "Veuiller saisir votre adresse E-mail d'inscription.",
			titlePage: "Ré-initialisation de mot de passe",
			textLoading: "Patienter SVP...",
			textButtonForgotPassword: "Valider",
			registerMsg: "Créer votre compte entreprise.",
			errorEmail: "Adresse E-mail invalide.",
		},
		validation: {
			errorVerificationCode: "Invalid verification code.",
			errorFillField: "Please fill all fields correctly.",
			titlePage: "Validation of account",
			titlePageSuccess: "Congratulations",
			titlePageAlreadyValidated: "Account already validated",
			subTitlePage:
				"We have sent you a verification code to your registration address. Please check your email inbox.",
			subTitlePageAlreadyValidated: "Use your email address and password you used to log in.",
			subTitlePageSuccessValidated: "Your account has been successfully validated.",
			inputCode: "Enter the verification code",
			textButtonvalidateCode: "Validate your account",
			textLoading: "Validation...",
			textButtonErrorValidateCode: "Return to authentication page",
			textButtonSuccessValidateCode: "Return to authentication page",
			subTitlePageUnknowError:
				"We were unable to validate your account. Please contact your technical manager",
			unableValidateAccount: "Unable to validate your account. Please try again later.",
		},
		dashboard: {},
		notification: {
			msgAuthenticationError: "Authentication error",
			msgRegistrationErrorWithStatusSuccess: "Registration Error",
		},
	},
};
