import { axios } from "../services";

export default (type: string, url: string, data: any, msg: string, config?: any) => {
	switch (type) {
		case "GET":
			return new Promise<any>((resolve, reject) => {
				axios
					.get(`${url}${data}`, config)
					.then((response: any) => {
						// handle success
						console.log(`%c Response success when ${msg}`, "color: #5CC62C;", response);
						resolve(response.data);
					})
					.catch((error: any) => {
						// handle error
						if (error.response) {
							// client received an error response (5xx, 4xx)
							console.error(`Response error when ${msg}`, error.response);
							reject(error.response);
						} else if (error.request) {
							// client never received a response, or request never left
							console.error(`Request error when ${msg}`, error.request);
							reject(error.request);
						} else {
							// anything else
							console.error(`Error when ${msg}`, error);
							reject(error);
						}
					});
			});
		case "POST":
			return new Promise<any>((resolve, reject) => {
				axios
					.post(`${url}`, data, config)
					.then((response: any) => {
						// handle success
						console.log(`%c Response success when ${msg}`, "color: #5CC62C;", response);
						resolve(response.data);
					})
					.catch((error: any) => {
						// handle error
						if (error.response) {
							// client received an error response (5xx, 4xx)
							console.error(`Response error when ${msg}`, error.response);
							reject(error.response);
						} else if (error.request) {
							// client never received a response, or request never left
							console.error(`Request error when ${msg}`, error.request);
							reject(error.request);
						} else {
							// anything else
							console.error(`Error when ${msg}`, error);
							reject(error);
						}
					});
			});
		case "PUT":
			return new Promise<any>((resolve, reject) => {
				axios
					.put(`${url}`, data, config)
					.then((response: any) => {
						// handle success
						console.log(`%c Response success when ${msg}`, "color: #5CC62C;", response);
						resolve(response.data);
					})
					.catch((error: any) => {
						// handle error
						if (error.response) {
							// client received an error response (5xx, 4xx)
							console.error(`Response error when ${msg}`, error.response);
							reject(error.response);
						} else if (error.request) {
							// client never received a response, or request never left
							console.error(`Request error when ${msg}`, error.request);
							reject(error.request);
						} else {
							// anything else
							console.error(`Error when ${msg}`, error);
							reject(error);
						}
					});
			});
		case "DEL":
			return new Promise<any>((resolve, reject) => {
				axios
					.delete(`${url}${data}`)
					.then((response: any) => {
						// handle success
						console.log(`%c Response success when ${msg}`, "color: #5CC62C;", response);
						resolve(response.data);
					})
					.catch((error: any) => {
						// handle error
						if (error.response) {
							// client received an error response (5xx, 4xx)
							console.error(`Response error when ${msg}`, error.response);
							reject(error.response);
						} else if (error.request) {
							// client never received a response, or request never left
							console.error(`Request error when ${msg}`, error.request);
							reject(error.request);
						} else {
							// anything else
							console.error(`Error when ${msg}`, error);
							reject(error);
						}
					});
			});
		default:
			return new Promise<any>((resolve, reject) => {
				resolve({});
			});
	}
};
